import React from "react"
import Layout from "../components/layout"
import styled from "styled-components"
import SEO from "../components/seo"

const Container = styled.div`
  display: grid;
`

const AboutContainer = styled.div`
  padding: 0 100px 0 100px;
  text-align: center;
  h1 {
    color: white;
  }
  p {
    color: white;
    font-size: 24px;
  }
  @media (max-width: 769px) {
    p {
      font-size: 18px;
    }
    padding: 10px;
  }
`

export default function About() {
  return (
    <Layout>
      <SEO title="About" />
      <Container>
        <AboutContainer>
          <h1 style={{ fontSize: "3rem" }}>ABOUT US</h1>
          <p>
            This is a platform that is For the Woman. Of the Woman. By the
            Woman. Alpha Woman is the ultimate woman that we all aspire to be.
            She has a sense of self worth and self love. She knows how
            imperative it is for her to grow and to help her fellow women grow
            as well; not just grow alone but she adds to their growth too by
            leaps and bounds. She flourishes and she helps flourish. She invests
            in herself. She is confident about herself and the decisions she
            takes. She knows that every step she takes towards achieving her
            goals is the right one. And in any event she sees failure, she takes
            it in her stride. It only serves to push her to work harder and to
            strive to achieve her goal. And once she's at the top, she's not
            alone as most would believe. She has her fellow women with her, for
            she knows that success is due to teamwork.
          </p>
          <p>
            We have begun Alpha Woman knowing full well that every woman is
            special. Every woman needs that platform to blossom, with just a
            nudge in the right direction. We are here to give YOU that nudge. We
            are here to make YOU feel special. And we are here to bring out that
            confidence from within. Join us at the Alpha Woman and make yourself
            an Alpha Woman. She's not a myth. She's a reality.
          </p>
          <p>
            <span style={{ fontSize: "2rem" }}> She is YOU.</span>
          </p>
        </AboutContainer>
      </Container>
    </Layout>
  )
}
